import jwtDecode from 'jwt-decode';
import getFingerprint from '@/utils/getFingerprint';
import api from '@/api';

export default {

  namespaced: true,

  state: {
    accessToken: null,
    refreshDisabled: false,
    refreshToken: null,
    refreshState: false,
    refreshAfters: [],
    expiresIn: null,
    userStatus: null,
    roles: [],
    viewAs: null,
  },

  getters: {
    isGuest(state) {
      return !state.accessToken;
    },
    isLogged(state, getters) {
      return !getters.isGuest;
    },
    isRefreshable(state, getters) {
      return getters.isLogged && Boolean(state.expiresIn);
    },
    isRole(state) {
      return (role) => state.roles.includes(role);
    },
    isAdmin(state, getters) {
      return getters.isRole('ROLE_ADMIN');
    },
    isViewAsAccess(state, getters) {
      return getters.isRole('ROLE_VIEW_AS_ACCESS')
        || getters.isRole('ROLE_VIEW_AS_ACCESS_RO');
    },
    isSalesPerson(state, getters) {
      return getters.isRole('ROLE_SALES_PERSON');
    },
    isSalesHead(state, getters) {
      return getters.isRole('ROLE_SALES_HEAD');
    },
    isSales(state, getters) {
      return getters.isSalesPerson || getters.isSalesHead;
    },
    isAdminOrSales(state, getters) {
      return getters.isAdmin || getters.isSales;
    },
    isManager(state, getters) {
      return !getters.isAdmin && getters.isRole('ROLE_RESTAURANT_ADMIN');
    },
    isStaff(state, getters) {
      return !getters.isAdmin && getters.isRole('ROLE_USER');
    },
    isOnlyStaff(state, getters) {
      return getters.isStaff && !getters.isOwner;
    },
    isOwner(state, getters) {
      return !getters.isAdmin && getters.isRole('ROLE_OWNER');
    },
    isOnlyOwner(state, getters) {
      return !getters.isStaff && getters.isOwner;
    },
    isOwnerCommon(state, getters, rootState, rootGetters) {
      return getters.isOwner && rootGetters['user/isCommon'];
    },
    isOwnerPersonal(state, getters, rootState, rootGetters) {
      return getters.isOwner && rootGetters['user/isPersonal'];
    },
    isOwnerAndStaff(state, getters) {
      return getters.isOwner && getters.isStaff;
    },
    isTroncMaster(state, getters) {
      return !getters.isAdmin && getters.isRole('ROLE_TRONC_MASTER');
    },
    isAccountant(state, getters) {
      return !getters.isAdmin && getters.isRole('ROLE_ACCOUNTANT');
    },
    isDistributionMaster(state, getters) {
      return !getters.isAdmin && getters.isRole('ROLE_DISTRIBUTION_MASTER');
    },
    isStaffMaster(state, getters) {
      return !getters.isAdmin && getters.isRole('ROLE_STAFF_MASTER');
    },
    isTroncStaff(state, getters) {
      return getters.isDistributionMaster && !getters.isTroncMaster;
    },
    isPayoutViewer(state, getters) {
      return getters.isRole('ROLE_PAYOUT_VIEWER');
    },
    isPromoMaster(state, getters) {
      return getters.isRole('ROLE_PROMO_MASTER');
    },
    isReadOnly(state, getters) {
      return state.viewAs?.isReadonly;
    },
    isTransationsManager(state, getters) {
      return getters.isRole('ROLE_TRANSACTIONS_MANAGER');
    },
    isNotificationsManager(state, getters) {
      return getters.isRole('ROLE_NOTIFICATIONS_MANAGER');
    },
    isSupportAdmin(state, getters) {
      return getters.isRole('ROLE_SUPPORT_ADMIN');
    },
    expiresInSeconds(state) {
      if (state.accessToken) {
        const { iat, exp } = jwtDecode(state.accessToken);

        return exp - iat;
      }

      return 0;
    },
  },

  mutations: {
    setToken(state, {
      expiresInSeconds,
      accessToken,
      refreshToken,
      returnPath,
      userStatus,
    }) {
      const newExpiresIn = new Date();

      newExpiresIn.setSeconds(newExpiresIn.getSeconds() + expiresInSeconds - 60);

      state.accessToken = accessToken;
      state.expiresIn = newExpiresIn.getTime();
      state.userStatus = userStatus;

      const { roles, viewAs } = jwtDecode(accessToken);
      const canViewAs = roles.includes('ROLE_VIEW_AS_ACCESS')
        || roles.includes('ROLE_VIEW_AS_ACCESS_RO');

      if (viewAs && canViewAs) {
        const {
          refreshToken: viewasRefreshToken = state.refreshToken,
          returnPath: viewasReturnPath = returnPath,
        } = state.viewAs || {};

        state.roles = viewAs.roles;
        state.viewAs = viewAs;
        state.viewAs.roles = roles;
        state.viewAs.refreshToken = viewasRefreshToken;
        state.viewAs.returnPath = viewasReturnPath;
        state.viewAs.isReadonly = roles.includes('ROLE_VIEW_AS_ACCESS_RO');
      } else {
        state.roles = roles;
        state.viewAs = null;
      }

      state.refreshToken = refreshToken;
    },
    removeViewasToken(state) {
      state.roles = state.viewAs.roles;
      state.refreshToken = state.viewAs.refreshToken;
      state.viewAs = null;
    },
    removeToken(state) {
      state.accessToken = null;
      state.refreshToken = null;
      state.expiresIn = null;
      state.userStatus = null;
      state.roles.splice(0);
      state.viewAs = null;
    },
    toggleRefresh(state, payload) {
      state.refreshState = payload;

      if (payload) {
        return;
      }

      if (state.accessToken) {
        state.refreshAfters.forEach((fn) => fn());
      }

      state.refreshAfters.splice(0);
    },
    runAfterRefresh(state, fn) {
      state.refreshAfters.push(fn);
    },
    setRefreshDisabled(state, payload) {
      state.refreshDisabled = payload;
    },
  },

  actions: {
    async login({ commit }, payload) {
      try {
        const { data } = await api.auth.login({
          identifier: await getFingerprint,
          ...payload,
        });

        commit('setToken', data);

        return data;
      } catch (error) {
        commit('removeToken');

        throw error;
      }
    },
    async loginAs({ commit }, { id, user, userId }) {
      const fingerprint = await getFingerprint;

      const { data } = await api.auth.loginAs({
        identifier: `viewas${fingerprint}`,
        id: user?.id || userId || id,
      });

      return data;
    },
    async refreshToken({ state, commit }, next) {
      if (state.refreshDisabled) {
        return next();
      }

      if (state.refreshState) {
        return new Promise((resolve) => {
          commit('runAfterRefresh', () => {
            resolve(next());
          });
        });
      }

      commit('toggleRefresh', true);

      try {
        const { data } = await api.auth.refresh({
          refreshToken: state.refreshToken,
        });

        commit('setToken', data);
      } catch (error) {
        commit('removeToken');

        throw error;
      } finally {
        commit('toggleRefresh', false);
      }

      return next();
    },
    async logout({ state, commit }) {
      commit('toggleRefresh', true);

      try {
        await api.auth.logout({
          refreshToken: state.refreshToken,
        });
      } catch (e) {
        // noop
      } finally {
        commit('removeToken');
        commit('toggleRefresh', false);
      }
    },
    async logoutAs({ state, commit }) {
      try {
        await api.auth.logoutAs({
          refreshToken: state.refreshToken,
        });
      } catch (e) {
        // noop
      }

      try {
        const { data } = await api.auth.refresh({
          refreshToken: state.viewAs.refreshToken,
        });

        commit('setToken', data);
      } catch (e) {
        commit('removeViewasToken');
        throw e;
      }
    },
    async logoutTotal({ commit }) {
      try {
        await api.auth.logoutTotal();
      } finally {
        commit('removeToken');
      }
    },
  },
};
